import React from "react";
import "./plans.css";
import {plansData} from "../../data/plansData";
import whitetick from '../../assets/whiteTick.png'
const Plans = () => {
  return (
    <div className="plans-container">
    <div className="blur plans-blur-1"></div>
    <div className="blur plans-blur-2"></div>
      <div className="programs-header">
      <span className="stroke-text">READY TO START</span>
      <span>YOUR JOURNEY</span>
      <span className="stroke-text">NOW WITHUS</span>
      </div>
      {/* plans card */}
      <div className="plans" id="plans">
      {plansData.map((plan,index) =>(
        <div className="plan" key={index}>
        {plan.icon}
        <span>{plan.name}</span>
        <span>${plan.price}</span>
        <div className="features">
        {plan.features.map((feature,index) => (
                <div className="feature">
                <img src={whitetick} alt="" />
                <span key={index}>{feature}</span>
                </div>
        ))}
        </div>
        <div><span>see more banefits -{">"}</span></div>
        <button className="btn">join now</button>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Plans;
