import React from "react";
import "./testimonials.css";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import { motion } from "framer-motion";
import {
  testimonialsData,
  TestimonialsData,
} from "../../data/testimonialsData";
import { useState } from "react";
const Testimonials = () => {
  const transition = { type: "spring", duration: 3 };
  const [selected, setselected] = useState(0);
  const tlength = testimonialsData.length;
  return (
    <div className="Testimonials " id="Testimonials">
      <div className="left-t">
        <span>Testimonials</span>
        <span className="stroke-text">What they</span>
        <motion.span
          initial={{ opacity: 0, x: -100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        >
          say about us
        </motion.span>
        <motion.span
          key={selected}
          initial={{ opacity: 0, x: -100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        >
          {testimonialsData[selected].review}
        </motion.span>
        <motion.span
          style={{ color: "var(--orange)" }}
          initial={{ opacity: 0, x: -100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        >
          {testimonialsData[selected].name}
        </motion.span>
        - {testimonialsData[selected].status}
      </div>
      <div className="right-t">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        ></motion.div>
        <motion.img
          key={selected}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
          src={testimonialsData[selected].image}
          alt=""
        />
        <div className="arrows">
          <img
            onClick={() => {
              //  إذا كانت الصورة الحالية هي الأولى
              selected === 0
                ? //  انتقل إلى الصورة الأخيرة
                  setselected(tlength - 1)
                : // انتقل إلى الصورة السابقة
                  setselected((prev) => prev - 1);
            }}
            src={leftArrow}
            alt=""
          />
          {/*  */}
          <img
            onClick={() => {
              // إذا كانت الصورة الحالية هي الأخيرة
              selected === tlength - 1
                ? // انتقل إلى الصورة الأولى
                  setselected(0)
                : // انتقل إلى الصورة التالية
                  setselected((prev) => prev + 1);
            }}
            src={rightArrow}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
