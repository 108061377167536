import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = (window.innerWidth = 768 ? true : false);
  return (
    <div className="Hero" id="Home">
      <div className="blur blur-h"></div>
      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "156px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={transition}
          ></motion.div>
          <span> the best fitness club in the town</span>
        </div>
        {/* hero landing */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">shape</span>
            <span>your</span>
            <div>
              <span>ideal body</span>
            </div>
            <div className="span">
              ideal body in here we will help you to shaer and buile your ideal
              body and live up your life to fullest
            </div>
          </div>
        </div>
        {/* figures */}
        <div className="figuers">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay="4" preFix="+" />
            </span>
            <span>expert coachs</span>
          </div>
          <div>
            <span>
              {" "}
              <NumberCounter end={200} start={30} delay="4" preFix="+" />
            </span>
            <span>members join</span>
          </div>
          <div>
            <span>
              {" "}
              <NumberCounter end={20} start={150} delay="4" preFix="+" />
            </span>
            <span>fithnss programs</span>
          </div>
        </div>
        {/*  hero btn*/}
        <div className="hero-btns">
          <button className="btn">get started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">join now</button>
        <motion.div
          initial={{ right: "-4rem" }}
          whileInView={{ right: "6rem" }}
          transition={transition}
          className="heart-rate"
        >
          <img src={heart} alt="" />
          <span>heart rate</span>
          <span>116bpm</span>
        </motion.div>
        {/* hero image */}
        <img src={hero_image} alt="" className="hero_image" />
        <motion.img
          src={hero_image_back}
          alt=""
          className="hero_image_back"
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
        />
        {/* calories */}
        <motion.div
          className="calories"
          initial={{ right: "30rem" }}
          whileInView={{ right: "33rem" }}
          transition={transition}
        >
          <img src={calories} alt="" />
          <div>
            <span>calories Brunde</span>
            <span>220 K</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default Hero;
