import React from "react";
import { useRef } from "react";
import "./join.css";
import emailjs from '@emailjs/browser'
const Join = () => {
    const form = useRef()
    // تعريف دالة sendEmail
const sendEmail = (e) => {
  e.preventDefault(); // منع السلوك الافتراضي للحدث، في هذه الحالة إعادة تحميل الصفحة

  // استخدام مكتبة emailjs لإرسال النموذج
  emailjs
    .sendForm(
      'service_1zw2j77', // معرف الخدمة المقدمة من EmailJS
      'template_v2xhckq', // معرف القالب الذي تريد استخدامه لإرسال البريد
      form.current, // الإشارة إلى النموذج الحالي الذي يتم إرساله
      {
        publicKey: 'dz0yoIgZb7edNzq_6', // مفتاحك العام من EmailJS
      }
    )
    .then(
      () => {
        console.log('SUCCESS!'); // إذا تم الإرسال بنجاح، طباعة "SUCCESS!"
      },
      (error) => {
        console.log('FAILED...', error.text); // إذا فشل الإرسال، طباعة "FAILED..." ونص الخطأ
      },
    );
};


  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">REDAY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-text">WITH US?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="email-containr" onSubmit={sendEmail}>
          <input
            type="email"
            name="user_email"
            placeholder="Enter your Email address"
          />
          <button className="btn btn-j">join now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
