import React from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { useState } from "react";
import {Link} from 'react-scroll'
const Header = () => {
  const mobile = (window.innerWidth = 768 ? true : false);
  const [menuOpened, setmenuOpened] = useState(false);
  return (
    <div className="header">
      <img src={logo} alt="" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "var(--appColor)",
            borderRadius: "6px",
            padding: "5px",
          }}
          onClick={()=>setmenuOpened(true)}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <ul className="hedaer-menu">
          <li >
          <Link
          onClick={()=>setmenuOpened(false)}
          to="Home"
          span={true}
          smooth={true}
          >Home</Link>
          </li>
          <li >
          <Link
          onClick={()=>setmenuOpened(false)}
          to="programs"
          span={true}
          smooth={true}
          >programs</Link>
          </li>
          <li >
          <Link
          onClick={()=>setmenuOpened(false)}
          to="Resaons"
          span={true}
          smooth={true}
          >whe us</Link>
          </li>
          <li >
          <Link
          onClick={()=>setmenuOpened(false)}
          to="plans"
          span={true}
          smooth={true}
          >plans</Link>
          </li>
          <li >
          <Link
          onClick={()=>setmenuOpened(false)}
          to="Testimonials"
          span={true}
          smooth={true}
          >testimonials</Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
